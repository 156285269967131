import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "Jessica Butler | Senior QA Engineer, Junior Software Engineer", // e.g: 'Name | Developer'
  lang: "EN", // e.g: en, es, fr, jp
  description: "Welcome to my Software + Quality Engineer Portfolio!", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hello, my name is",
  name: "Jessica Butler",
  subtitle:
    "I'm a Software QA Engineer/aspiring Software Engineer with over 8 years of dedicated experience in various industries, including fintech, healthcare and SaaS.",
  cta: "Learn More",
};

// ABOUT DATA
export const aboutData = {
  img: "profile.png",
  paragraphOne: "",
  paragraphTwo: "",
  paragraphThree: "",
  resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "marigoldmoon.png",
    title: "Marigold Moon Bodywork",
    info: "Custom built website for a local massage therapist",
    info2:
      "I created this website for a company that I believe in and wanted to support - my sister's company! The design is inspired by her logo that she created herself. I created this website using React and JavaScript. I used Git + GitHub for change management, and Netlify for deploying.",
    url: "https://marigoldmoonbodywork.com/",
    repo: "https://github.com/jessicapdx/marigold-moon-bodywork", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "chelcegeronphotog.png",
    title: "Chelce Geron Photography",
    info: "Designed branding + customized Wix website",
    info2:
      "I wanted to support a friend's photography business, so I helped create a solution that would be an easy way to manage her content and customer contacts. Wix was a great solution for this! I created a custom logo and presented a few color schemes until she was ecstatic with the result.",
    url: "https://www.chelcegeronphotography.com/",
    repo: "https://github.com/jessicapdx",
  },
  {
    id: nanoid(),
    img: "postmanproj.png",
    title: "Postman Petfinder API Project",
    info: "Exploratory Postman collection using Petfinder API",
    info2:
      "I created this repository to continue exploring Postman's powerful features and growing my API testing knowledge through experimentation!",
    url: "https://github.com/jessicapdx/petfinder-postman",
    repo: "https://github.com/jessicapdx/petfinder-postman", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "cypressproj.png",
    title: "Parabank Cypress Example",
    info: "Parabank is a test automation playground",
    info2:
      "I created this test suite using Cypress, TypeScript and Mocha assertions. I am constantly learning more, and this example will continously grow!",
    url: "https://github.com/jessicapdx/parabank-cypress-example",
    repo: "https://github.com/jessicapdx/parabank-cypress-example", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "weatherapp.png",
    title: "Weather Application",
    info: "Weather application created using React + JavaScript + 3rd party API",
    info2:
      "Created during SheCodes React Frontend Development coursework. Deployed using Netlify + GitHub",
    url: "https://jb-shecodes-final-project.netlify.app/",
    repo: "https://github.com/jessicapdx/react-weather-v2", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "dictionaryapp.png",
    title: "Dictionary Application",
    info: "Dictionary application created using React + JavaScript + 3rd party API",
    info2:
      "Created during SheCodes Frontend Development coursework. Deployed using Netlify + GitHub",
    url: "https://jb-react-dictionary-app.netlify.app/",
    repo: "https://github.com/jessicapdx/dictionary-project", // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "Interested to learn more?",
  btn: "Get In Touch",
  email: "jessica.b.butler@outlook.com",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "linkedin",
      url: "https://www.linkedin.com/in/jessicabbutler/",
    },
    {
      id: nanoid(),
      name: "github",
      url: "https://github.com/jessicapdx",
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
